<template>
  <v-container fluid>
    <V-row>
      <v-col md="12">
        <SettingItem
          @loadData="loadData"
          collection="tags"
          :items="tags"
          :itemHeader="tagItemHeader"
        />
      </v-col>
    </V-row>
  </v-container>
</template>

<script>
import SettingItem from "./SettingItem/index";
import ApiService from "@/services/api.service";

export default {
  components: {
    SettingItem,
  },
  data() {
    return {
      tags: [],
      tagItemHeader: [
        // { text: "sequence", value: "sequence" },
        { text: "En Title", value: "english_title" },
        { text: "Ar Title", value: "arabic_title" },
        { text: "published Book", value: "pNum" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {},
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.fetchData();
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchData() {
      try {
        const data = await ApiService.get(
          "items/tags?fields=id,products.product_id.status,arabic_title,english_title,arabic_description,english_description,background_color,sequence,parent_tag.english_title,parent_tag.id,tag_image.id"
        );
        this.tags = data.data.data;
        this.tags.forEach((element) => {
          let num = 0;
          element.products.forEach((element1) => {
            if (
              element1.product_id &&
              element1.product_id.status == "published"
            ) {
              num++;
            }
          });
          element.pNum = num;
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.fetchData(undefined);
  },
};
</script>

<style></style>
