<template>
  <v-dialog v-model="dialog" max-width="900px" persistent>
    <v-card>
      <v-card-title>
        <span color="primary" class="headline">Products</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="attributeForm" v-model="valid3">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="productTagForm.sequence"
                :rules="numberRules"
                :counter="10"
                label="Sequence"
                outlined
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="productTagForm.product_id"
                :items="products"
                outlined
                dense
                style="padding:10px,margin: 10px"
                chips
                small-chips
                label="product"
                item-text="arabic_title"
                item-value="id"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="10">
              <v-overlay :value="submitted">
                <v-progress-circular
                  indeterminate
                  size="64"
                ></v-progress-circular> </v-overlay
            ></v-col>
            <v-col cols="12" md="2">
              <v-btn color="success" @click="submit">
                Submit
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-data-table
                :headers="productTagHeader"
                :items="product_tag"
                :items-per-page="5"
                class="elevation-1 "
              >
                <template v-slot:top>
                  <v-toolbar flat class="mb-7">
                    <!-- delete item -->
                    <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                        <v-card-title class="headline"
                          >Are you sure you want to delete this
                          user?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeDelete"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteItemConfirm()"
                            >OK</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template
                  v-slot:item.actions="{ item }"
                  style="textAlign:right !important"
                >
                  <!-- <v-icon
                    color="primary"
                    small
                    class="mr-2"
                    @click="editAttr(item)"
                  >
                    mdi-pencil
                  </v-icon> -->

                  <v-icon
                    color="error"
                    small
                    class="mr-2"
                    @click="deleteAttr(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="close">
          Cancel
        </v-btn>
        <v-btn color="blue darken-1" text @click="submit">
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- snackbar -->
    <v-snackbar v-model="snackbar" absolute top :multi-line="true">
      {{ error }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  props: ["tag_id", "dialog"],
  data() {
    return {
      product_tag: [],
      products: [],
      submitted_succ: false,
      valid3: false,
      snackbar: false,
      valid: false,
      submitted: false,
      loading: false,
      error: "",
      loadingCategories: false,
      productTagHeader: [
        {
          text: "Product",
          value: "product_id.arabic_title",
        },
        {
          text: "Sequence",
          value: "sequence",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],

      record: null,
      dialogDelete: false,
      productTagForm: {
        id: null,
        sequence: null,
        tag_id: null,
        product_id: null,
      },
      textRules: [(v) => !!v || "field is required"],
      numberRules: [
        (v) => !!v || "field is required",
        (v) =>
          Number.isInteger(Number(v)) || "The value must be an integer number",
        (v) => v > 0 || "The value must be greater than zero",
      ],
      // =================== for updating users ===================
      initialPayload: {},
    };
  },
  methods: {
    close() {
      this.$emit("closeDialog");
    },
    resetForm() {
      Object.keys(this.productTagForm).forEach((element) => {
        this.productTagForm[element] = null;
      });
    },

    // async update() {
    //   this.attribute_value_id_init.forEach(async (element) => {
    //     const result = this.attribute_value_id.filter(
    //       (attr) => attr == element.attribute_value_id.id
    //     );
    //     if (result.length == 0) {
    //       await ApiService.delete(
    //         `items/product_tag/${element.id}`
    //       );
    //     }
    //   });
    //   this.attribute_value_id.forEach(async (element) => {
    //     const result = this.attribute_value_id_init.filter(
    //       (tag) => tag.attribute_value_id.id == element
    //     );
    //     if (result.length == 0) {
    //       let item = {};
    //       item["product_id"] = this.productTagForm.id;
    //       item["attribute_value_id"] = element;
    //       await ApiService.post(`items/product_tag`, item);
    //     }
    //   });
    //   await ApiService.update(
    //     `items/products`,
    //     this.productTagForm.id,
    //     this.productTagForm
    //   );
    // },
    async submit() {
      try {
        if (this.$refs.attributeForm.validate()) {
          this.submitted = true;
          if (this.productTagForm.id) {
            await this.update();
          } else {
            this.productTagForm.tag_id = this.tag_id;
            const newProduct = await ApiService.post(
              `items/product_tag`,
              this.productTagForm
            );
          }
          this.resetForm();
          this.submitted = false;
          this.loadData();
          const msg = this.record
            ? "record updated successfully"
            : "record inserted successfully";
          this.$emit("formSubmitted", msg);
        } else {
          this.snackbar = true;
          this.error = "please fill all required fields";
        }
      } catch (error) {
        if (error.message !== "") {
          this.error = error;
        } else {
          this.error = "sorry, something went wrong";
        }
        this.snackbar = true;
        console.log(error);
        this.submitted = false;
      }
    },
    async loadData() {
      this.loading = true;
      const data = await ApiService.get(
        `items/product_tag?fields=id,product_id.arabic_title,tag_id.arabic_title,sequence&filter[tag_id]=${this.tag_id}`
      );
      console.log(data);
      this.product_tag = data.data.data;
    },
    deleteAttr(record) {
      this.record = record;
      this.dialogDelete = true;
    },
    closeDelete() {
      this.record = null;
      this.dialogDelete = false;
    },
    async deleteItemConfirm() {
      try {
        this.loading = true;
        await ApiService.delete(`items/product_tag/${this.record.id}`);
        this.loadData();
        this.loading = false;
        this.closeDelete();

        alert("Product deleted successfully");
        this.loadData();
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    this.loadData();
    const products = await ApiService.get(
      "items/products?fields=id,arabic_title"
    );
    this.products = products.data.data;
  },
};
</script>
<style lang="css">
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "~quill/dist/quill.bubble.css";
.image-area {
  position: relative;
  width: 50%;
  background: #333;
}
.image-area img {
  max-width: 100%;
  height: auto;
}
.remove-image {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #555;
  border: 3px solid #fff;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.remove-image:hover {
  background: #e54e4e;
  padding: 3px 7px 5px;
  top: -11px;
  right: -11px;
}
.remove-image:active {
  background: #e54e4e;
  top: -10px;
  right: -11px;
}
</style>
